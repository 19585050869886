import { FunctionComponent, useState } from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Collapse } from '@mui/material';
import {
  Computer,
  Assignment,
  FolderOpenRounded,
  SecurityRounded,
  ExpandLess,
  ExpandMore,
  AddToQueue,
  CameraRoll,
  Insights,
  SlowMotionVideoOutlined,
  AttachMoney,
  Group,
  SupervisedUserCircle,
} from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { useUser } from '../api/user';
import PermissionWrapper from '../common/PermissionWrapper';
import { checkPermissions, permissions } from '../utils/permissions';
import { leftnavVariables } from '../theme/leftnav';

const insightsPermissions = [permissions.billingInsights, permissions.editJobInsights];
const adminPermissions = [permissions.adminDeployments, permissions.userAdmin, permissions.transcodeJobs];

function hasAtLeastOnePermission(required: string[][], userPermissions: string[]) {
  return required.some((perm) => checkPermissions(userPermissions, perm));
}

export const LeftMenu: FunctionComponent = () => {
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openInsights, setOpenInsights] = useState(false);
  const [openTalent, setOpenTalent] = useState(false);
  const [openAcademy, setOpenAcademy] = useState(false);
  const [openCloudStation, setOpenCloudStation] = useState(false);
  const [openProjects, setOpenProjetcs] = useState(false);
  const { data: user } = useUser();

  const hasInsights = hasAtLeastOnePermission(insightsPermissions, user?.permissions || []);
  const hasAdmin = hasAtLeastOnePermission(adminPermissions, user?.permissions || []);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: '240px',
        height: '100%',
        '& .MuiDrawer-paper': {
          width: '240px',
          position: 'sticky',
          height: '100%',
          bgcolor: `${leftnavVariables.leftMenuBGColor}`,
        },
        position: 'sticky',
      }}
    >
      <List>
        {hasInsights && (
          <>
            <ListItem button onClick={() => setOpenInsights(!openInsights)}>
              <ListItemIcon sx={{ color: '#370060' }}>
                <svg width="24" height="24" fill="#7202C6">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.477 1.336a2 2 0 0 1 1.046 0c.397.108.732.37.999.58l.074.059L20.38 7.25l.077.06c.377.292.709.55.956.884a3 3 0 0 1 .477.975c.112.4.112.82.111 1.298v7.372c0 .527 0 .981-.03 1.356-.033.395-.104.789-.297 1.167a3 3 0 0 1-1.311 1.311c-.378.193-.772.264-1.167.296-.375.031-.83.031-1.356.031H6.16c-.527 0-.981 0-1.356-.03-.395-.033-.789-.104-1.167-.297a3 3 0 0 1-1.311-1.311c-.193-.378-.264-.772-.296-1.167C2 18.82 2 18.365 2 17.838V10.468c0-.477-.001-.897.111-1.298a3 3 0 0 1 .477-.975c.247-.334.58-.592.956-.884l.077-.06 6.783-5.275a28.8 28.8 0 0 0 .074-.059c.267-.21.602-.472 1-.58ZM10 20h4v-6.4c0-.296 0-.459-.01-.575l-.001-.014h-.014A8.207 8.207 0 0 0 13.4 13h-2.8c-.296 0-.459 0-.575.01l-.014.001v.014c-.01.116-.011.278-.011.575V20Zm6 0v-6.432c0-.252 0-.498-.017-.706a2.022 2.022 0 0 0-.201-.77 2 2 0 0 0-.874-.874 2.022 2.022 0 0 0-.77-.201C13.93 11 13.684 11 13.432 11h-2.864c-.252 0-.498 0-.706.017a2.022 2.022 0 0 0-.77.201 2 2 0 0 0-.874.874 2.022 2.022 0 0 0-.201.77C8 13.07 8 13.316 8 13.568V20H6.2c-.577 0-.949 0-1.232-.024-.272-.022-.373-.06-.422-.085a1 1 0 0 1-.437-.437c-.025-.05-.063-.15-.085-.422C4 18.75 4 18.377 4 17.8v-7.235c0-.63.009-.755.037-.856a1 1 0 0 1 .159-.325c.063-.085.155-.168.653-.556l6.783-5.275c.187-.145.286-.221.36-.271L12 3.277l.007.005c.075.05.175.126.361.271l6.783 5.275c.498.388.59.471.653.556a1 1 0 0 1 .159.325c.028.1.037.225.037.856V17.8c0 .576 0 .949-.024 1.232-.022.272-.06.372-.085.422a1 1 0 0 1-.437.437c-.05.025-.15.063-.422.085C18.75 20 18.377 20 17.8 20H16Z"
                    fill="currentcolor"
                  />
                </svg>
              </ListItemIcon>
              <ListItemText
                primary="Insights"
                sx={{
                  color: `${leftnavVariables.fontColor}`,
                  fontFamily: `${leftnavVariables.fontFamily}`,
                  fontSize: `${leftnavVariables.fontSize}`,
                  fontWeight: '500 !important',
                  lineHeight: `${leftnavVariables.lineHeight}`,
                  letterSpacing: `${leftnavVariables.letterSpacing}`,
                }}
              />
              {openInsights ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openInsights} timeout="auto" unmountOnExit>
              <PermissionWrapper requiredPermissions="editJobInsights">
                <ListItem
                  button
                  sx={{ paddingLeft: `${leftnavVariables.paddingLeft}` }}
                  component={NavLink}
                  to="/insights/edit-jobs"
                >
                  <ListItemIcon
                    sx={{
                      color: `${leftnavVariables.fontColor}`,
                      fontFamily: `${leftnavVariables.fontFamily}`,
                      fontSize: `${leftnavVariables.fontSize}`,
                    }}
                  >
                    <SlowMotionVideoOutlined />
                  </ListItemIcon>
                  <ListItemText
                    primary="Edit Jobs"
                    sx={{
                      color: `${leftnavVariables.fontColor}`,
                      fontFamily: `${leftnavVariables.fontFamily}`,
                      fontSize: `${leftnavVariables.fontSize}`,
                    }}
                  />
                </ListItem>
              </PermissionWrapper>
              <PermissionWrapper requiredPermissions="billingInsights">
                <ListItem
                  button
                  sx={{
                    paddingLeft: `${leftnavVariables.paddingLeft}`,
                    color: `${leftnavVariables.fontColor}`,
                    fontFamily: `${leftnavVariables.fontFamily}`,
                    fontSize: `${leftnavVariables.fontSize}`,
                  }}
                  component={NavLink}
                  to="/insights/billing"
                >
                  <ListItemIcon>
                    <AttachMoney />
                  </ListItemIcon>
                  <ListItemText
                    primary="Billing"
                    sx={{
                      color: `${leftnavVariables.fontColor}`,
                      fontFamily: `${leftnavVariables.fontFamily}`,
                      fontSize: `${leftnavVariables.fontSize}`,
                    }}
                  />
                </ListItem>
              </PermissionWrapper>
            </Collapse>
            <ListItem button onClick={() => setOpenTalent(!openTalent)}>
              <ListItemIcon sx={{ color: '#370060' }}>
                {/* <Insights fontSize="small" sx={{color: `${leftnavVariables.fontColor}`, fontFamily: `${leftnavVariables.fontFamily}`, fontSize: `${leftnavVariables.fontSize}`}}  /> */}
                <svg width="24" height="24" fill="#370060">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.5 4a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7ZM4 7.5a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0Zm11.104-4.477a1 1 0 0 1 1.341-.45 5.5 5.5 0 0 1 0 9.855 1 1 0 0 1-.89-1.791 3.5 3.5 0 0 0 0-6.274 1 1 0 0 1-.45-1.34ZM9.5 16c-2.447 0-4.67 1.333-6.108 3.493-.09.134-.158.237-.215.327a2.61 2.61 0 0 0-.096.162c.146.017.354.018.741.018h11.356c.387 0 .595-.002.74-.018-.02-.039-.05-.09-.095-.162-.057-.09-.125-.193-.215-.327C14.17 17.333 11.947 16 9.5 16Zm-7.773 2.384C3.477 15.756 6.285 14 9.5 14s6.023 1.756 7.773 4.384l.024.036c.154.23.318.476.432.71.138.281.237.604.214.99a2.02 2.02 0 0 1-.242.82 2.02 2.02 0 0 1-.548.655c-.33.25-.684.337-.998.373a8.534 8.534 0 0 1-.933.032H3.778c-.33 0-.657 0-.933-.032-.314-.037-.668-.124-.998-.373a2.021 2.021 0 0 1-.548-.655 2.021 2.021 0 0 1-.242-.82 1.934 1.934 0 0 1 .214-.99c.114-.234.278-.48.432-.71l.024-.036Zm15.38-2.996a1 1 0 0 1 1.342-.445c1.634.821 3.016 2.129 4.016 3.74l.034.054c.149.238.343.548.406.957.074.473-.055.925-.246 1.268a2.009 2.009 0 0 1-.948.879c-.383.163-.797.16-1.123.16L20.5 22a1 1 0 1 1 0-2c.215 0 .328 0 .41-.006l.001-.004.003-.006a11.598 11.598 0 0 0-.149-.247c-.822-1.325-1.937-2.365-3.214-3.006a1 1 0 0 1-.445-1.343Z"
                    fill="currentcolor"
                  />
                </svg>
              </ListItemIcon>
              <ListItemText
                primary="Talent"
                sx={{
                  color: `${leftnavVariables.fontColor}`,
                  fontFamily: `${leftnavVariables.fontFamily}`,
                  fontSize: `${leftnavVariables.fontSize}`,
                  fontWeight: `${leftnavVariables.fontWeight}`,
                  lineHeight: `${leftnavVariables.lineHeight}`,
                  letterSpacing: `${leftnavVariables.letterSpacing}`,
                }}
              />
              {openTalent ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openTalent} timeout="auto" unmountOnExit>
              <PermissionWrapper requiredPermissions="editJobInsights">
                <ListItem
                  button
                  sx={{ paddingLeft: `${leftnavVariables.paddingLeft}` }}
                  component={NavLink}
                  to="/admin/users"
                >
                  <ListItemIcon
                    sx={{
                      color: `${leftnavVariables.fontColor}`,
                      fontFamily: `${leftnavVariables.fontFamily}`,
                      fontSize: `${leftnavVariables.fontSize}`,
                    }}
                  ></ListItemIcon>
                  <ListItemText
                    primary="People"
                    sx={{
                      color: `${leftnavVariables.fontColor}`,
                      fontFamily: `${leftnavVariables.fontFamily}`,
                      fontSize: `${leftnavVariables.fontSize}`,
                    }}
                  />
                </ListItem>
              </PermissionWrapper>
              <PermissionWrapper requiredPermissions="billingInsights">
                <ListItem
                  button
                  sx={{
                    paddingLeft: `${leftnavVariables.paddingLeft}`,
                    color: `${leftnavVariables.fontColor}`,
                    fontFamily: `${leftnavVariables.fontFamily}`,
                    fontSize: `${leftnavVariables.fontSize}`,
                  }}
                  component={NavLink}
                  to="#"
                >
                  <ListItemIcon>{/* <AttachMoney /> */}</ListItemIcon>
                  <ListItemText
                    primary="Team"
                    sx={{
                      color: `${leftnavVariables.fontColor}`,
                      fontFamily: `${leftnavVariables.fontFamily}`,
                      fontSize: `${leftnavVariables.fontSize}`,
                    }}
                  />
                </ListItem>
              </PermissionWrapper>
            </Collapse>
            <ListItem button onClick={() => setOpenAcademy(!openAcademy)}>
              <ListItemIcon sx={{ color: '#370060' }}>
                {/* <Insights fontSize="small" sx={{color: `${leftnavVariables.fontColor}`, fontFamily: `${leftnavVariables.fontFamily}`, fontSize: `${leftnavVariables.fontSize}`}}  /> */}
                <svg width="24" height="24" fill="#7202C6">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.803 4.52Zm.197.098L19.764 8.5l-.192.096a1.063 1.063 0 0 0-.037.019L17 9.882l-4.553-2.276a1 1 0 1 0-.894 1.788L14.763 11 12 12.382 4.465 8.615a.985.985 0 0 0-.037-.02L4.236 8.5 12 4.618Zm-9 5.5-1.447-.724a1 1 0 0 1 0-1.788l9.642-4.822.026-.013c.093-.047.286-.146.502-.186a1.5 1.5 0 0 1 .554 0 1.902 1.902 0 0 1 .528.2l9.642 4.82a1 1 0 0 1 0 1.79L21 10.117v6.267c.001.286.003.645-.116.978a2 2 0 0 1-.489.762c-.253.246-.58.395-.84.513a24.84 24.84 0 0 0-.072.032l-6.4 2.933-.05.024c-.19.088-.43.198-.69.244a2 2 0 0 1-.685 0c-.261-.046-.5-.156-.69-.244l-.051-.023-6.4-2.934.416-.909-.416.91-.072-.033c-.26-.118-.587-.267-.84-.513a2 2 0 0 1-.489-.762c-.119-.333-.117-.692-.116-.978v-6.267Zm2 1v5.189a6.8 6.8 0 0 0 .006.381l.005.003c.066.035.159.078.339.161l6.4 2.933c.131.06.197.09.246.11l.004.002.004-.002c.049-.02.115-.05.246-.11l6.4-2.933c.18-.083.273-.126.339-.16l.005-.004v-.006c.006-.075.006-.177.006-.375v-5.189l-1 .5V14.5a1 1 0 1 1-2 0v-1.882l-3.195 1.598-.026.013c-.093.047-.286.146-.502.186a1.5 1.5 0 0 1-.554 0c-.216-.04-.41-.139-.502-.186l-.026-.013L5 11.117Zm6.803 1.362Zm.394 0Zm0-7.96Z"
                    fill="currentcolor"
                  />
                </svg>
              </ListItemIcon>
              <ListItemText
                primary="Academy"
                sx={{
                  color: `${leftnavVariables.fontColor}`,
                  fontFamily: `${leftnavVariables.fontFamily}`,
                  fontSize: `${leftnavVariables.fontSize}`,
                  fontWeight: `${leftnavVariables.fontWeight}`,
                  lineHeight: `${leftnavVariables.lineHeight}`,
                  letterSpacing: `${leftnavVariables.letterSpacing}`,
                }}
              />
              {openAcademy ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openAcademy} timeout="auto" unmountOnExit>
              <PermissionWrapper requiredPermissions="editJobInsights">
                <ListItem button sx={{ paddingLeft: `${leftnavVariables.paddingLeft}` }} component={NavLink} to="#">
                  <ListItemIcon
                    sx={{
                      color: `${leftnavVariables.fontColor}`,
                      fontFamily: `${leftnavVariables.fontFamily}`,
                      fontSize: `${leftnavVariables.fontSize}`,
                    }}
                  ></ListItemIcon>
                  <ListItemText
                    primary="Courses"
                    sx={{
                      color: `${leftnavVariables.fontColor}`,
                      fontFamily: `${leftnavVariables.fontFamily}`,
                      fontSize: `${leftnavVariables.fontSize}`,
                    }}
                  />
                </ListItem>
              </PermissionWrapper>
              <PermissionWrapper requiredPermissions="billingInsights">
                <ListItem
                  button
                  sx={{
                    paddingLeft: `${leftnavVariables.paddingLeft}`,
                    color: `${leftnavVariables.fontColor}`,
                    fontFamily: `${leftnavVariables.fontFamily}`,
                    fontSize: `${leftnavVariables.fontSize}`,
                    fontWeight: `${leftnavVariables.fontWeight}`,
                  }}
                  component={NavLink}
                  to="#"
                >
                  <ListItemIcon>{/* <AttachMoney /> */}</ListItemIcon>
                  <ListItemText
                    primary="Assesments"
                    sx={{
                      color: `${leftnavVariables.fontColor}`,
                      fontFamily: `${leftnavVariables.fontFamily}`,
                      fontSize: `${leftnavVariables.fontSize}`,
                    }}
                  />
                </ListItem>
              </PermissionWrapper>
            </Collapse>
          </>
        )}

        <ListItem button onClick={() => setOpenProjetcs(!openProjects)}>
          <ListItemIcon sx={{ color: '#370060' }}>
            <svg width="24" height="24" fill="#7202C6">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.161 2h.482c1.084 0 1.958 0 2.666.058.728.06 1.369.185 1.961.487A5 5 0 0 1 12 4a5 5 0 0 1 1.73-1.455c.592-.302 1.232-.428 1.961-.487C16.4 2 17.273 2 18.357 2h.482c.527 0 .982 0 1.356.03.395.033.789.104 1.167.297a3 3 0 0 1 1.311 1.311c.193.378.264.772.296 1.167.031.375.031.83.031 1.356v8.678c0 .527 0 .981-.03 1.356-.033.395-.104.789-.297 1.167a3 3 0 0 1-1.311 1.311c-.378.193-.772.264-1.167.296-.375.031-.83.031-1.356.031h-1.414c-1.314 0-1.763.01-2.156.13a2.997 2.997 0 0 0-1.033.552c-.317.261-.575.629-1.304 1.723l-.1.15a1 1 0 0 1-1.664 0l-.1-.15c-.73-1.094-.987-1.462-1.304-1.723a2.999 2.999 0 0 0-1.033-.552c-.393-.12-.842-.13-2.156-.13H5.16c-.527 0-.981 0-1.356-.03-.395-.033-.789-.104-1.167-.297a3 3 0 0 1-1.311-1.311c-.193-.378-.264-.772-.296-1.167C1 15.82 1 15.365 1 14.838V6.162c0-.527 0-.981.03-1.356.033-.395.104-.789.297-1.167a3 3 0 0 1 1.311-1.311c.378-.193.772-.264 1.167-.296C4.18 2 4.635 2 5.161 2ZM11 9.4c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 0 0-1.311-1.311c-.263-.134-.611-.226-1.216-.276C7.529 4.001 6.736 4 5.6 4h-.4c-.577 0-.949 0-1.232.024-.272.022-.373.06-.422.085a1 1 0 0 0-.437.437c-.025.05-.063.15-.085.422C3 5.25 3 5.623 3 6.2v8.6c0 .577 0 .949.024 1.232.022.272.06.372.085.422a1 1 0 0 0 .437.437c.05.025.15.063.422.085C4.25 17 4.623 17 5.2 17h1.522c1.11 0 1.877 0 2.59.216.615.186 1.188.49 1.688.893V9.4Zm2 8.71a5 5 0 0 1 1.688-.894c.713-.217 1.48-.216 2.59-.216H18.8c.577 0 .949 0 1.232-.024.272-.022.373-.06.422-.085a1 1 0 0 0 .437-.437c.025-.05.063-.15.085-.422C21 15.75 21 15.377 21 14.8V6.2c0-.577 0-.949-.024-1.232-.022-.272-.06-.373-.085-.422a1 1 0 0 0-.437-.437c-.05-.025-.15-.063-.422-.085C19.75 4 19.377 4 18.8 4h-.4c-1.137 0-1.929 0-2.546.051-.605.05-.953.142-1.216.276a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216-.05.617-.051 1.41-.051 2.546v8.71Z"
                fill="currentcolor"
              />
            </svg>
            {/* <Assignment /> */}
          </ListItemIcon>
          <ListItemText
            primary="Projects"
            sx={{
              color: `${leftnavVariables.fontColor}`,
              fontFamily: `${leftnavVariables.fontFamily}`,
              fontSize: `${leftnavVariables.fontSize}`,
              fontWeight: `${leftnavVariables.fontWeight}`,
              lineHeight: `${leftnavVariables.lineHeight}`,
              letterSpacing: `${leftnavVariables.letterSpacing}`,
            }}
          />
          {openAcademy ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openProjects} timeout="auto" unmountOnExit>
          <PermissionWrapper requiredPermissions="editJobInsights">
            <ListItem button sx={{ paddingLeft: `${leftnavVariables.paddingLeft}` }} component={NavLink} to="/projects">
              <ListItemIcon
                sx={{
                  color: `${leftnavVariables.fontColor}`,
                  fontFamily: `${leftnavVariables.fontFamily}`,
                  fontSize: `${leftnavVariables.fontSize}`,
                }}
              ></ListItemIcon>
              <ListItemText
                primary="All Projects"
                sx={{
                  color: `${leftnavVariables.fontColor}`,
                  fontFamily: `${leftnavVariables.fontFamily}`,
                  fontSize: `${leftnavVariables.fontSize}`,
                }}
              />
            </ListItem>
            <ListItem button sx={{ paddingLeft: `${leftnavVariables.paddingLeft}` }} component={NavLink} to="#">
              <ListItemIcon
                sx={{
                  color: `${leftnavVariables.fontColor}`,
                  fontFamily: `${leftnavVariables.fontFamily}`,
                  fontSize: `${leftnavVariables.fontSize}`,
                }}
              ></ListItemIcon>
              <ListItemText
                primary="Tasks"
                sx={{
                  color: `${leftnavVariables.fontColor}`,
                  fontFamily: `${leftnavVariables.fontFamily}`,
                  fontSize: `${leftnavVariables.fontSize}`,
                }}
              />
            </ListItem>
          </PermissionWrapper>
        </Collapse>
        <PermissionWrapper requiredPermissions="userWorkstations">
          <ListItem button onClick={() => setOpenCloudStation(!openCloudStation)}>
            <ListItemIcon sx={{ color: '#370060' }}>
              <Computer />
            </ListItemIcon>
            <ListItemText
              primary="Cloudstations"
              sx={{
                color: `${leftnavVariables.fontColor}`,
                fontFamily: `${leftnavVariables.fontFamily}`,
                fontSize: `${leftnavVariables.fontSize}`,
                fontWeight: `${leftnavVariables.fontWeight}`,
                lineHeight: `${leftnavVariables.lineHeight}`,
                letterSpacing: `${leftnavVariables.letterSpacing}`,
              }}
            />
            {openAcademy ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openCloudStation} timeout="auto" unmountOnExit>
            <PermissionWrapper requiredPermissions="editJobInsights">
              <ListItem
                button
                sx={{ paddingLeft: `${leftnavVariables.paddingLeft}` }}
                component={NavLink}
                to="/file-browser/"
              >
                <ListItemIcon
                  sx={{
                    color: `${leftnavVariables.fontColor}`,
                    fontFamily: `${leftnavVariables.fontFamily}`,
                    fontSize: `${leftnavVariables.fontSize}`,
                  }}
                ></ListItemIcon>
                <ListItemText
                  primary="File Browser"
                  sx={{
                    color: `${leftnavVariables.fontColor}`,
                    fontFamily: `${leftnavVariables.fontFamily}`,
                    fontSize: `${leftnavVariables.fontSize}`,
                  }}
                />
              </ListItem>
              <ListItem
                button
                sx={{ paddingLeft: `${leftnavVariables.paddingLeft}` }}
                component={NavLink}
                to="/workstations"
              >
                <ListItemIcon
                  sx={{
                    color: `${leftnavVariables.fontColor}`,
                    fontFamily: `${leftnavVariables.fontFamily}`,
                    fontSize: `${leftnavVariables.fontSize}`,
                  }}
                ></ListItemIcon>
                <ListItemText
                  primary="All Cloudstations"
                  sx={{
                    color: `${leftnavVariables.fontColor}`,
                    fontFamily: `${leftnavVariables.fontFamily}`,
                    fontSize: `${leftnavVariables.fontSize}`,
                  }}
                />
              </ListItem>
            </PermissionWrapper>
          </Collapse>
        </PermissionWrapper>
        {hasAdmin && (
          <>
            <ListItem button onClick={() => setOpenAdmin(!openAdmin)}>
              <ListItemIcon sx={{ color: '#370060' }}>
                <svg width="24" height="24" fill="#7202C6">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-3.874 1A4.002 4.002 0 0 1 22 8a4 4 0 0 1-7.874 1H3a1 1 0 0 1 0-2h11.126ZM6 14a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-4 2a4 4 0 0 1 7.874-1H21a1 1 0 1 1 0 2H9.874A4.002 4.002 0 0 1 2 16Z"
                    fill="currentcolor"
                  />
                </svg>
              </ListItemIcon>
              <ListItemText
                primary="Settings"
                sx={{
                  color: `${leftnavVariables.fontColor}`,
                  fontFamily: `${leftnavVariables.fontFamily}`,
                  fontSize: `${leftnavVariables.fontSize}`,
                }}
              />
              {openAdmin ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openAdmin} timeout="auto" unmountOnExit>
              <PermissionWrapper requiredPermissions="adminDeployments">
                <ListItem
                  button
                  sx={{
                    paddingLeft: 4,
                    color: `${leftnavVariables.fontColor}`,
                    fontFamily: `${leftnavVariables.fontFamily}`,
                    fontSize: `${leftnavVariables.fontSize}`,
                  }}
                  component={NavLink}
                  to="/admin/deployments"
                >
                  <ListItemIcon>
                    <AddToQueue />
                  </ListItemIcon>
                  <ListItemText primary="Deployments" />
                </ListItem>
                <PermissionWrapper requiredPermissions="transcodeJobs">
                  <ListItem
                    button
                    sx={{
                      paddingLeft: 4,
                      color: `${leftnavVariables.fontColor}`,
                      fontFamily: `${leftnavVariables.fontFamily}`,
                      fontSize: `${leftnavVariables.fontSize}`,
                    }}
                    component={NavLink}
                    to="/transcode/jobs"
                  >
                    <ListItemIcon>
                      <CameraRoll />
                    </ListItemIcon>
                    <ListItemText
                      primary="Transcode Jobs"
                      sx={{ color: '#370060 !important', fontFamily: 'Montserrat, sans-serif', fontSize: '16px' }}
                    />
                  </ListItem>
                </PermissionWrapper>
              </PermissionWrapper>
              <PermissionWrapper requiredPermissions="userAdmin">
                <ListItem
                  button
                  sx={{
                    color: '#370060 !important',
                    paddingLeft: 4,
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: '16px',
                  }}
                  component={NavLink}
                  to="/admin/users"
                >
                  <ListItemIcon>
                    <Group />
                  </ListItemIcon>
                  <ListItemText
                    primary="Users"
                    sx={{ color: '#370060 !important', fontFamily: 'Montserrat, sans-serif', fontSize: '16px' }}
                  />
                </ListItem>
              </PermissionWrapper>
              <PermissionWrapper requiredPermissions="roleAdmin">
                <ListItem
                  button
                  sx={{
                    color: '#370060 !important',
                    paddingLeft: 4,
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: '16px',
                  }}
                  component={NavLink}
                  to="/admin/roles"
                >
                  <ListItemIcon>
                    <SupervisedUserCircle />
                  </ListItemIcon>
                  <ListItemText
                    primary="Roles"
                    sx={{ color: '#370060 !important', fontFamily: 'Montserrat, sans-serif', fontSize: '16px' }}
                  />
                </ListItem>
              </PermissionWrapper>
            </Collapse>
          </>
        )}
      </List>
    </Drawer>
  );
};

export default LeftMenu;
