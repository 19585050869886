import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ShareIcon from '@mui/icons-material/LinkOutlined';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import {
  Avatar,
  Divider,
  Typography,
  Tooltip,
  IconButton,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Card,
  TextField,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import { useProjects } from '../../api/projects';
import { useSnackbar } from '../../contexts/Snackbar';
import { getLocalDateTime } from '../../utils/getLocalDateTime';
import PermissionWrapper from '../../common/PermissionWrapper';
import CreateProjectDialog from './create/CreateProjectDialog';
import Skeleton from './Skeleton';
import { buttonColor, colors, purpleColor, radious } from '../../theme/designtoken';

function Projects() {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const { isLoading, data, isError, hasNextPage, fetchNextPage, isFetchingNextPage } = useProjects(searchTerm);
  const [openDialog, setOpenDialog] = useState(false);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const { openSnackbar } = useSnackbar();

  const projects = useMemo(() => {
    return data ? data.pages.flatMap((page) => page.projects) : [];
  }, [data]);

  if (isError) {
    openSnackbar('Error loading projects. Please try again', { alert: 'error' });
  }

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }} aria-label="Projects page">
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', padding: 1 }}>
        <Typography variant="h6">Projects</Typography>
        <TextField
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{ endAdornment: <SearchIcon /> }}
          sx={{ paddingLeft: '24px', marginLeft: 2 }}
        />
        <PermissionWrapper requiredPermissions="createProject">
          <Button
            onClick={() => setOpenDialog(true)}
            sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}
            color="primary"
            startIcon={<AddIcon />}
          >
            Create New Project
          </Button>
        </PermissionWrapper>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', padding: 1, gap: 1 }}>
        <Typography variant="body1">Grid</Typography>
        <IconButton
          color={viewMode === 'grid' ? 'primary' : 'default'}
          onClick={() => setViewMode('grid')}
          aria-label="Grid view"
        >
          <ViewModuleIcon />
        </IconButton>
        <Typography variant="body1">List</Typography>
        <IconButton
          color={viewMode === 'list' ? 'primary' : 'default'}
          onClick={() => setViewMode('list')}
          aria-label="List view"
        >
          <ViewListIcon />
        </IconButton>
      </Box>
      {projects.length === 0 && (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
          <Typography>{searchTerm ? 'No projects found' : 'No projects created yet'}</Typography>
        </Box>
      )}
      {projects.length > 0 && (
        <>
          {viewMode === 'grid' ? (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 400px))',
                width: '100%',
                gap: 2,
                padding: 1,
              }}
            >
              {projects.map((project) => (
                <Card
                  key={project.UUID}
                  elevation={7}
                  sx={{
                    borderRadius: parseInt(`${radious.borderradius1}`),
                    border: '2px solid #B15EFF',
                    width: '100%',
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        sx={{ color: '2px solid #B15EFF', bgcolor: `${purpleColor.purple1}` }}
                      >
                        {project.projectName
                          .split(' ')
                          .map((w) => w.charAt(0))
                          .join('')
                          .substring(0, 2)
                          .toUpperCase()}
                      </Avatar>
                    }
                    title={<span style={{ fontWeight: 700 }}>{project.projectName}</span>}
                    subheader={getLocalDateTime(project.dateAddedInMicroSeconds / 1000, {
                      timeStyle: 'medium',
                      dateStyle: 'short',
                    })}
                  />
                  <Divider variant="middle" />
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{ wordBreak: 'break-word' }}
                      dangerouslySetInnerHTML={{ __html: project.description }}
                    />
                  </CardContent>
                  <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Tooltip title="Copy link to project" arrow>
                      <IconButton
                        aria-label="Copy link"
                        edge="start"
                        onClick={() => {
                          copy(`${window.location.protocol}//${window.location.host}/project/${project.UUID}`);
                          openSnackbar('Project link copied');
                        }}
                      >
                        <ShareIcon />
                      </IconButton>
                    </Tooltip>
                    <Button
                      color="primary"
                      variant="outlined"
                      sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}
                      onClick={async () => {
                        history.push(`/project/${project.UUID}`);
                      }}
                    >
                      View Project
                    </Button>
                  </CardActions>
                </Card>
              ))}
            </Box>
          ) : (
            <List sx={{ width: '100%' }}>
              {/* Header Row */}
              <ListItem sx={{ padding: 2, display: 'flex', alignItems: 'center', borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>
                <Typography variant="h5" sx={{ width: '6%' }}>Icon</Typography>
                <Typography variant="h5" sx={{ width: '30%' }}>Project Name</Typography>
                <Typography variant="h5" sx={{ width: '30%' }}>Description</Typography>
                <Typography variant="h5" sx={{ width: '20%' }}>Due Date</Typography>
                <Typography variant="h5" sx={{ width: '20%' }}>Status</Typography>
                <Typography variant="h5" sx={{ width: '20%' }}>Actions</Typography>
              </ListItem>
              {projects.map((project) => (
                <ListItem
                  key={project.UUID}
                  sx={{ borderBottom: '1px solid #ddd', padding: 2, display: 'flex', alignItems: 'flex-start', width: '100%' }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: `${purpleColor.purple1}` }}>
                      {project.projectName
                        .split(' ')
                        .map((w) => w.charAt(0))
                        .join('')
                        .substring(0, 2)
                        .toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <Box sx={{ display: 'flex', gap: 1, width: '100%' }}>
                    <Typography variant="h6" component="span">
                      {project.projectName}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1, width: '100%' }}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      dangerouslySetInnerHTML={{ __html: project.description }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1, width: '100%' }}>
                    <Typography variant="caption" color="textSecondary">
                      {getLocalDateTime(project.dateAddedInMicroSeconds / 1000, {
                        timeStyle: 'medium',
                        dateStyle: 'short',
                      })}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1, width: '100%' }}>
                    <Tooltip title="Copy link to project" arrow>
                      <IconButton
                        aria-label="Copy link"
                        onClick={() => {
                          copy(`${window.location.protocol}//${window.location.host}/project/${project.UUID}`);
                          openSnackbar('Project link copied');
                        }}
                      >
                        <ShareIcon />
                      </IconButton>
                    </Tooltip>
                    <Button color="primary" variant="outlined" onClick={() => history.push(`/project/${project.UUID}`)}>
                      View Project
                    </Button>
                  </Box>
                </ListItem>
              ))}
            </List>
          )}
          {hasNextPage && (
            <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', padding: 2 }}>
              <Button variant="outlined" onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
                {isFetchingNextPage ? 'Loading...' : 'Load More'}
              </Button>
            </Box>
          )}
        </>
      )}
      {openDialog && <CreateProjectDialog open={openDialog} onClose={() => setOpenDialog(false)} />}
    </Box>
  );
}

export default Projects;
